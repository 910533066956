import {
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./orderEntry.module.scss";
import { useDispatch, useSelector } from "react-redux";
import CustomDropDown from "../../atoms/customDropDown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QuantitiesTable } from "./quantitiesTable";
import CustomButton from "../../atoms/customButton";
import { useNavigate } from "react-router";
import moment from "moment";
import { SingleOrderEntryTable } from "./singleOrderEntryTable";
import { OrderEntryModal } from "./orderEntryModal";
import { FileUpload } from "./fileUpload";
import {
  clearCartOrder,
  clearCartOrderSuccess,
  getCalendarData,
  getCartProducts,
  getCartTotalProductsCount,
  getMultiQuote,
  getMultiQuoteSuccess,
  getProductList,
  getQuote,
  getQuoteSuccess,
  saveMultiAddresses,
  setLoading,
  setSelectedShipToAccount,
  updateCartAction,
} from "../../../state/actions";
import {
  getComparator,
  isWeekend,
  shouldDisableDateFunc,
  stableSort,
  quantityCheck,
} from "../../helper/util";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
var _ = require("lodash");

const activeChip = {
  backgroundColor: "#f25529",
  borderRadius: "10px",
  border: "1px solid #f25529",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#f25529",
  },
};

const chip = {
  border: "1px solid #f25529",
  borderRadius: "10px",
  color: "#f25529",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
};

export const OrderEntry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCartProducts: any = useSelector(
    (state: any) => state?.cartReducer?.selectedCartProducts
  );
  const saveAccountDetails = useSelector(
    (state: any) => state?.commonReducer?.saveAccountDetails
  );
  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [modalErrMsg, setModalErrMsg] = useState("");
  const [openFileModal, setOpenFileModal] = useState(false);
  const [shipToAccountsArray, setShipToAccountsArray]: any = useState([]);
  const [shippingAgentsAccountsArray, setShippingAgentsAccountsArray]: any =
    useState([]);
  const [paymentTermArray, setPaymentTermArray]: any = useState([]);
  const [basicCalender, setBasicCalender] = useState<any>(true);
  const [startingMinDate, setStartingMinDate] = useState<any>(null);
  const [calenderId, setCalenderId] = useState("");
  const [topPageError, setTopPageError] = useState("");

  const createAddressFormat = (toObject: any) => {
    return `${toObject?.accountNumber} - ${toObject?.accountName}, ${toObject?.address?.street}, ${toObject?.address?.townCity} (${toObject?.address?.country})`;
  };

  const currentDealerAccountFormat = (dealer: any) => {
    const format = `${dealer?.number} - ${dealer?.name}, ${dealer?.address?.street}, ${dealer?.address?.townCity} (${dealer?.address?.country})`;
    return format;
  };

  useEffect(() => {
    dispatch(getQuoteSuccess({}));
    dispatch(getMultiQuoteSuccess({}));
    let currentDate = new Date();
    setStartingMinDate(currentDate.toDateString());
    let orderEntryObjTemp: any = orderEntryObj;
    let findNextPossibleDate = new Date(currentDate);
    for (let i = 0; i < 14; i++) {
      findNextPossibleDate.setDate(currentDate.getDate() + i);
      if (!isWeekend(findNextPossibleDate)) {
        orderEntryObjTemp = {
          ...orderEntryObjTemp,
          requestedDeliveryDate: findNextPossibleDate?.toDateString(),
        };
        break;
      }
    }
    setOrderEntryObj(orderEntryObjTemp);
    dispatch(setLoading(true));
    dispatch(
      getCartProducts({
        shoppingContextToken,
        usernameOrEmail: userDetails?.username,
        key: 2,
      })
    );
    dispatch(getProductList({}));
    dispatch(clearCartOrderSuccess({}));
    dispatch(
      getCartTotalProductsCount({
        usernameOrEmail: userDetails?.username,
        shoppingContextToken,
      })
    );
  }, []);

  const multiSelectedAddresses = useSelector(
    (state: any) => state?.cartReducer?.multiSelectedAddresses
  );

  useEffect(() => {
    let selectedDeliveryOption =
      selectedCartProducts?.isPartialDelivery === false
        ? "complete"
        : "partial";
    setRadioButtonValue(selectedDeliveryOption);
    setMultiSelectChanged(false);
    dispatch(
      getCartTotalProductsCount({
        usernameOrEmail: userDetails?.username,
        shoppingContextToken,
      })
    );
    let shipToAccountsArr: any = [];
    let shippingAgentsAccountsArr: any = [];
    let orderEntryObjTemp: any = orderEntryObj;
    if (selectedCartProducts?.requestedDeliveryDate) {
      let [day, month, year] =
        selectedCartProducts.requestedDeliveryDate.split("/");
      orderEntryObjTemp = {
        ...orderEntryObjTemp,
        requestedDeliveryDate: new Date(`${month}/${day}/${year}`),
      };
      setOrderEntryObj(orderEntryObjTemp);
    }
    if (selectedCartProducts?.shipToAccountId?.length > 1) {
      let shipAcc = "0";
      let defaultAdd = selectedCartProducts?.shipToAccountId?.filter(
        (acc: any) => acc?.defaultSalesArea?.length > 0
      );
      if (selectedCartProducts?.shoppingCart?.isMultiShipTo) {
        shipAcc = "multiship";
      } else if (
        defaultAdd?.length > 0 &&
        (selectedCartProducts?.selectedShipToAccountIds?.[0] === "" ||
          selectedCartProducts?.selectedShipToAccountIds === undefined ||
          selectedCartProducts?.selectedShipToAccountIds?.length === 0)
      ) {
        shipAcc = defaultAdd[0]?.accountNumber;
      } else if (
        selectedCartProducts?.selectedShipToAccountIds?.[0] === "" ||
        selectedCartProducts?.selectedShipToAccountIds === undefined ||
        selectedCartProducts?.selectedShipToAccountIds?.length === 0
      ) {
        shipAcc = "0";
      } else {
        shipAcc = selectedCartProducts?.selectedShipToAccountIds?.[0];
      }
      orderEntryObjTemp = {
        ...orderEntryObjTemp,
        shipToAccounts: shipAcc,
      };
    } else {
      orderEntryObjTemp = {
        ...orderEntryObjTemp,
        shipToAccounts:
          selectedCartProducts?.shipToAccountId?.[0]?.accountNumber,
      };
    }
    setOrderEntryObj(orderEntryObjTemp);
    setIsMultiRequestedDeliveryDate(
      selectedCartProducts?.shoppingCart?.isMultiRequestedDeliveryDate
    );
    let paymentTermArr: any = [];
    if (selectedCartProducts?.shipToAccountId?.length > 0) {
      if (selectedCartProducts?.shipToAccountId?.length > 1) {
        shipToAccountsArr?.push({
          value: "0",
          label: t("dnet.please_select_shipto.text")?.toString(),
          sort: "0",
          disabled: true,
        });
      }
      selectedCartProducts?.shipToAccountId?.map((acc: any) => {
        shipToAccountsArr?.push({
          value: acc?.accountNumber,
          label: `${acc?.accountNumber} - ${acc?.accountName}, ${acc?.address?.street}, ${acc?.address?.townCity}, (${acc?.address?.country})`,
          sort: `${acc?.accountName}, ${acc?.address?.street}, ${acc?.address?.townCity}, (${acc?.address?.country})`,
          singleAccount: false,
          multiSelect: true,
        });
      });
      shipToAccountsArr = stableSort(
        shipToAccountsArr,
        getComparator("asc", "sort")
      );
      if (selectedCartProducts?.shipToAccountId?.length > 1) {
        shipToAccountsArr?.push({
          value: "multiship",
          label: t("dnet.multiple_delivery.text"),
        });
      }
    }
    if (selectedCartProducts?.shippingAgentsAccountIds?.length > 0) {
      selectedCartProducts?.shippingAgentsAccountIds?.map((acc: any) => {
        shippingAgentsAccountsArr?.push({
          value: acc?.accountNumber,
          label: createAddressFormat(acc),
        });
      });
    }
    if (selectedCartProducts?.paymentTermId?.length > 0) {
      selectedCartProducts?.paymentTermId?.map((acc: any) => {
        paymentTermArr?.push({
          value: acc?.name,
          label: `${acc?.name}-${acc?.description}`,
        });
      });
    }
    if (multiSelectedAddresses?.length === 0) {
      dispatch(saveMultiAddresses({ multiSelectArray: shipToAccountsArr }));
    }
    setShipToAccountsArray(shipToAccountsArr);
    setShippingAgentsAccountsArray(shippingAgentsAccountsArr);
    setPaymentTermArray(paymentTermArr);
    if (
      callQuoteHandler &&
      (selectedCartProducts?.errorCount === 0 ||
        selectedCartProducts?.errorCount === "")
    ) {
      handleQuotationButton(selectedCartProducts);
    } else {
      dispatch(setLoading(false));
    }
    dispatch(
      setSelectedShipToAccount({
        selectedShipToAccount: selectedCartProducts?.selectedShipToAccountIds
          ? selectedCartProducts?.selectedShipToAccountIds
          : [""],
        multiShipTo: selectedCartProducts?.shoppingCart?.isMultiShipTo,
        multiRDD:
          selectedCartProducts?.shoppingCart?.isMultiRequestedDeliveryDate,
        partialDelivery: selectedCartProducts?.isPartialDelivery,
        deliveryDate: selectedCartProducts?.requestedDeliveryDate,
        shoppingContextToken,
      })
    );
  }, [selectedCartProducts]);

  const [dateError, setDateError] = useState("");
  const [orderEntryObj, setOrderEntryObj] = useState({
    invoiceToAccounts: currentDealer?.accNum,
    shippingAgentAccounts:
      selectedCartProducts?.shippingAgentsAccountIds?.length > 0
        ? selectedCartProducts?.shippingAgentsAccountIds[0]
        : "",
    payerToAccounts: currentDealer?.accNum,
    paymentTerm:
      selectedCartProducts?.paymentTermId?.length > 0
        ? selectedCartProducts?.paymentTermId?.[0]?.["name"]
        : "",
    shipToAccounts:
      selectedCartProducts?.shipToAccountId?.length === 1
        ? selectedCartProducts?.shipToAccountId[0]?.["accountNumber"]
        : "0",
    requestedDeliveryDate: null,
  });
  const [accHelperText, setAccHelperText] = useState("");

  const closeModal: any = () => {
    setModalErrMsg("");
    setOpenModal(false);
  };

  const closeFileModal: any = () => {
    setOpenFileModal(false);
  };

  const handleOpenFileModal: any = () => {
    setOpenFileModal(true);
  };

  const handleReportChange = (fieldname: any, data: any) => {
    let tempObj = orderEntryObj;
    if (fieldname === "requestedDeliveryDate") {
      tempObj = {
        ...tempObj,
        [fieldname]: new Date(data?.["$d"]),
      };
      setDateError("");
    } else if (fieldname === "shipToAccounts") {
      setDateError("");
      let selectedShipAccount = selectedCartProducts?.shipToAccountId?.filter(
        (acc: any) => acc?.accountNumber === data
      );
      setCalenderId(selectedShipAccount[0]?.calenderId);
      tempObj = {
        ...tempObj,
        [fieldname]: data,
      };
      setAccHelperText("");
    } else {
      tempObj = {
        ...tempObj,
        [fieldname]: data,
      };
    }
    setOrderEntryObj(tempObj);
  };

  const calendarDetails = useSelector(
    (state: any) => state?.commonReducer?.calendarDetails
  );

  const handleDateErrorFunc = (e: any) => {
    if (e === "shouldDisableDate")
      setDateError(t("dnet.invalid_rdd_error.text")?.toString());
  };

  const shouldDisableDate = (date: any) => {
    return shouldDisableDateFunc(date, basicCalender, calendarDetails);
  };

  const basicCalenderLogic = () => {
    if (
      selectedCartProducts?.isCustomCalendarEnabled &&
      !(
        orderEntryObj?.shipToAccounts === "0" ||
        orderEntryObj?.shipToAccounts === "multiship"
      )
    ) {
      let tempCalenderId =
        calenderId === "" || calenderId === undefined || calenderId === null
          ? selectedCartProducts?.defaultCalendarId
          : calenderId;
      if (tempCalenderId !== "") {
        setBasicCalender(false);
        let cdate = new Date();
        let currentDate = new Date();
        let twoDaysLater = new Date(currentDate);
        twoDaysLater.setDate(currentDate.getDate() + 1);
        setStartingMinDate(twoDaysLater.toDateString());
        dispatch(
          getCalendarData({
            calendarId: tempCalenderId,
            year: cdate.getFullYear(),
          })
        );
      } else {
        setBasicCalender(true);
      }
    } else {
      setBasicCalender(true);
    }
  };

  useEffect(() => {
    basicCalenderLogic();
  }, [calenderId]);

  let orderLine = t("dnet.n_order_line.text");
  orderLine = orderLine.replace(
    "{0}",
    selectedCartProducts?.shoppingCart?.lineItems === undefined
      ? 0
      : selectedCartProducts?.shoppingCart?.lineItems?.length
  );
  orderLine = orderLine.replace("{1}", "100");

  let invalid_data =
    selectedCartProducts?.errorCount > 1
      ? t("dnet.m_invalid_lines_plural.text")
      : t("dnet.m_invalid_line.text");
  invalid_data = invalid_data.replace("{0}", selectedCartProducts?.errorCount);
  orderLine =
    selectedCartProducts?.errorCount > 0
      ? orderLine + " " + invalid_data
      : orderLine;

  const [currentActiveChip, setCurrentActiveChip] = useState(0);
  const [currentChipName, setCurrentChipName] = useState("");

  const handleChip = (chipName: any) => {
    if (chipName === "pricesAndAvailibility") {
      setCurrentChipName(chipName);
    } else {
      setCurrentChipName(chipName);
      currentActiveChip === 0
        ? setCurrentActiveChip(1)
        : setCurrentActiveChip(0);
    }
  };

  const [quoteButtonSuccessClick, setQuoteButtonSuccessClick] = useState(false);
  const [callQuoteHandler, setCallQuoteHandler] = useState(false);

  const handleQuotationButton = (prods: any) => {
    let updateProduct = JSON.parse(JSON.stringify(prods));
    let lineItem: any = [];
    if (orderEntryObj?.shipToAccounts === "multiship") {
      updateProduct?.shoppingCart?.lineItems?.forEach((item: any) => {
        let lineItemTemp: any = {};
        if (item?.productSku?.name !== "") {
          lineItemTemp = JSON.parse(JSON.stringify(item));
          let multiLineItemsTemp: any = [];
          item?.multiLineItems?.forEach((multiItem: any) => {
            multiItem?.quantity !== "" &&
              parseInt(multiItem?.quantity) > 0 &&
              multiLineItemsTemp?.push(multiItem);
          });
          lineItemTemp.multiLineItems = multiLineItemsTemp;
          lineItem?.push(lineItemTemp);
        }
      });
    } else if (isMultiRequestedDeliveryDate) {
      updateProduct?.shoppingCart?.lineItems?.forEach((item: any) => {
        let lineItemTemp: any = {};
        if (item?.productSku?.name !== "") {
          lineItemTemp = JSON.parse(JSON.stringify(item));
          let subLineItemsTemp: any = [];
          item?.subLineItems?.forEach((multiItem: any) => {
            if (
              multiItem?.quantity !== "" &&
              parseInt(multiItem?.quantity) > 0 &&
              multiItem?.requestedDeliveryDate !== null
            ) {
              let tempMultiItem: any = {
                ...multiItem,
                requestedDeliveryDate: moment(
                  multiItem?.requestedDeliveryDate
                )?.format("YYYY-MM-DD"),
              };
              subLineItemsTemp?.push(tempMultiItem);
            }
          });
          lineItemTemp.subLineItems = subLineItemsTemp;
          lineItem?.push(lineItemTemp);
        }
      });
      updateProduct.shoppingCart.lineItems = lineItem;
    } else {
      updateProduct?.shoppingCart?.lineItems?.forEach(
        (item: any) =>
          item?.productSku?.name !== "" &&
          item?.quantity !== "" &&
          parseInt(item?.quantity) > 0 &&
          lineItem?.push(item)
      );
    }
    let dateErrorText = "";
    let addressHelperText = "";
    let errorText = "";
    if (
      orderEntryObj?.shipToAccounts === "0" &&
      prods?.shipToAccountId?.length > 1
    ) {
      addressHelperText = t("dnet.force_ship_to.text");
      setAccHelperText(addressHelperText);
      dispatch(setLoading(false));
    }
    if (orderEntryObj?.requestedDeliveryDate === null) {
      dateErrorText = t("dnet.empty_rdd_error.text").toString();
      setDateError(dateErrorText);
      dispatch(setLoading(false));
    }
    if (prods?.shoppingCart?.lineItems?.length === 0) {
      errorText = t("dnet.empty_shoppping_cart.text").toString();
      setTopPageError(errorText);
      setQuoteButtonSuccessClick(false);
      setCallQuoteHandler(false);
      dispatch(setLoading(false));
    }
    if (addressHelperText === "" && dateErrorText === "" && errorText === "") {
      let updatedCartData: any = JSON.parse(JSON.stringify(updateProduct));
      updatedCartData.shipToAccountID =
        orderEntryObj?.shipToAccounts === "0" ||
        orderEntryObj?.shipToAccounts === "multiship"
          ? ""
          : orderEntryObj?.shipToAccounts;
      updatedCartData.requestedDeliveryDate =
        orderEntryObj?.requestedDeliveryDate === null
          ? null
          : moment(orderEntryObj?.requestedDeliveryDate).format("YYYY-MM-DD");
      updatedCartData.isPartialDelivery = radioButtonValue === "partial";
      updatedCartData.invoiceTo = orderEntryObj?.invoiceToAccounts;
      updatedCartData.payerToAccount = orderEntryObj?.payerToAccounts;
      updatedCartData.shoppingCart.isMultiShipTo =
        orderEntryObj?.shipToAccounts === "multiship";
      updatedCartData.shoppingCart.isMultiRequestedDeliveryDate =
        isMultiRequestedDeliveryDate;
      updatedCartData?.shoppingCart?.lineItems?.forEach((item: any) => {
        if (
          item?.subLineItems === undefined ||
          item?.subLineItems?.length === 0
        ) {
          item.subLineItems = [
            {
              quantity: "",
              requestedDeliveryDate: null,
            },
            {
              quantity: "",
              requestedDeliveryDate: null,
            },
            {
              quantity: "",
              requestedDeliveryDate: null,
            },
            {
              quantity: "",
              requestedDeliveryDate: null,
            },
          ];
        }
      });
      let allWarrantyItems: any = [];
      updatedCartData?.shoppingCart?.lineItems?.forEach((item: any) => {
        item?.warrantyLineItems?.length > 0 &&
          item?.isWarranty &&
          item?.warrantyLineItems?.map((warrantyItem: any) => {
            warrantyItem?.quantity !== null &&
              warrantyItem?.quantity !== "" &&
              warrantyItem?.quantity !== "0" &&
              warrantyItem?.quantity !== 0 &&
              allWarrantyItems?.push(warrantyItem);
          });
      });
      allWarrantyItems?.forEach((warrantyProduct: any) => {
        let warrantySubLineItem: any[] = [];

        warrantyProduct?.subLineItems?.map((subWarranty: any) => {
          if (
            subWarranty?.quantity !== "" &&
            parseInt(subWarranty?.quantity) > 0 &&
            subWarranty?.requestedDeliveryDate !== null
          ) {
            let tempMultiItem: any = {
              ...subWarranty,
              requestedDeliveryDate: moment(
                subWarranty?.requestedDeliveryDate
              )?.format("YYYY-MM-DD"),
            };
            warrantySubLineItem?.push(tempMultiItem);
          }
        });
        warrantyProduct.subLineItems = warrantySubLineItem;
      });

      updatedCartData.shoppingCart.lineItems = [
        ...updatedCartData.shoppingCart.lineItems,
        ...allWarrantyItems,
      ];

      updatedCartData?.shoppingCart?.lineItems?.forEach((item: any) => {
        if (item?.warrantyLineItems?.length > 0 && item?.isWarranty) {
          let validWarrantyItem = item?.warrantyLineItems?.filter(
            (warrantyItem: any) =>
              warrantyItem.quantity !== "" &&
              warrantyItem.quantity !== null &&
              warrantyItem.quantity !== "0" &&
              warrantyItem.quantity !== 0
          );
          item.warrantyLineItems = validWarrantyItem;
        }
      });
      updatedCartData?.shoppingCart?.lineItems?.forEach((item: any) => {
        item?.warrantyLineItems?.length > 0 &&
          item?.isWarranty &&
          item?.warrantyLineItems?.map((warrantyItem: any) => {
            let validWarrantySubLineItems = warrantyItem?.subLineItems.filter(
              (subLineItem: any) =>
                subLineItem.quantity !== 0 &&
                subLineItem.quantity !== "0" &&
                subLineItem.quantity !== "" &&
                subLineItem.quantity !== null &&
                subLineItem.requestedDeliveryDate !== null
            );
            warrantyItem.subLineItems = validWarrantySubLineItems;
          });
      });
      dispatch(setLoading(true));
      if (orderEntryObj?.shipToAccounts === "multiship") {
        let selectedMultiShipAccs: any = [];
        shipToAccountsArray?.forEach(
          (acc: any) =>
            acc?.multiSelect && selectedMultiShipAccs?.push(acc?.value)
        );
        updatedCartData.shoppingCart.multiShiptoAccs = selectedMultiShipAccs;
        updatedCartData?.shoppingCart?.lineItems?.forEach((item: any) => {
          item?.multiLineItems?.forEach((subItem: any) => {
            subItem.productSku = item?.productSku;
          });
        });
        quantityCheck(updatedCartData);
        setQuoteButtonSuccessClick(true);
        dispatch(getQuoteSuccess({}));
        dispatch(getMultiQuote(updatedCartData));
      } else {
        quantityCheck(updatedCartData);
        setQuoteButtonSuccessClick(true);
        dispatch(getMultiQuoteSuccess({}));
        dispatch(getQuote(updatedCartData));
      }
    }
  };

  const singleQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.singleQuoteLoad
  );

  const multiQuoteLoad = useSelector(
    (state: any) => state?.cartReducer?.multiQuoteLoad
  );

  const quoteNavigator = (
    deliverToAddress: any,
    isMultiRequestedDeliveryDate: any
  ) => {
    navigate(`/${ROUTES_LABEL?.ORDER_ENTRY}/${ROUTES_LABEL?.QUOTATION}`, {
      state: {
        selectedDeliverTo: deliverToAddress,
        multiRdd: isMultiRequestedDeliveryDate,
      },
    });
  };

  const quoteTopErrorHandler = () => {
    setTopPageError(t("dnet.cart_generic_error.text")?.toString());
    setQuoteButtonSuccessClick(false);
    setCallQuoteHandler(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let deliverToAddress = "";
    if (orderEntryObj?.shipToAccounts === "multiship") {
      deliverToAddress = orderEntryObj?.shipToAccounts;
    } else {
      let selectedAccArray = shipToAccountsArray?.filter((acc: any) => {
        return acc?.value === orderEntryObj?.shipToAccounts;
      });
      deliverToAddress = selectedAccArray[0]?.["label"];
    }
    dispatch(
      getCartTotalProductsCount({
        usernameOrEmail: userDetails?.username,
        shoppingContextToken,
      })
    );
    if (orderEntryObj?.shipToAccounts === "multiship") {
      if (
        !_.isEmpty(multiQuoteLoad?.data) &&
        multiQuoteLoad?.success &&
        quoteButtonSuccessClick
      ) {
        dispatch(setLoading(false));
        quoteNavigator(deliverToAddress, isMultiRequestedDeliveryDate);
      } else if (
        _.isEmpty(multiQuoteLoad?.data) &&
        multiQuoteLoad?.success &&
        quoteButtonSuccessClick
      ) {
        dispatch(setLoading(false));
        quoteTopErrorHandler();
      }
    } else if (!_.isEmpty(singleQuoteLoad) && quoteButtonSuccessClick) {
      dispatch(setLoading(false));
      quoteNavigator(deliverToAddress, isMultiRequestedDeliveryDate);
    } else if (_.isEmpty(singleQuoteLoad?.data) && quoteButtonSuccessClick) {
      dispatch(setLoading(false));
      quoteTopErrorHandler();
    }
  }, [singleQuoteLoad, multiQuoteLoad]);

  const [radioButtonValue, setRadioButtonValue] = useState("partial");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioButtonValue((event.target as HTMLInputElement).value);
  };

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const updateButtonClickHandler = (prods: any, callCheck: boolean = false) => {
    setCallQuoteHandler(callCheck);
    prods?.chip === "pricesAndAvailibility" && setCurrentActiveChip(1);
    let updatedCartData: any = {};
    updatedCartData.usernameOrEmail = userDetails?.username;
    updatedCartData.shipToAccountId =
      orderEntryObj?.shipToAccounts === "0"
        ? []
        : [orderEntryObj?.shipToAccounts];
    updatedCartData.requestedDeliveryDate =
      orderEntryObj?.requestedDeliveryDate === null
        ? null
        : moment(orderEntryObj?.requestedDeliveryDate).format("DD/MM/YYYY");
    updatedCartData.spToken = shoppingContextToken;
    updatedCartData.key = 2;
    updatedCartData.isPartialDelivery = radioButtonValue === "partial";
    updatedCartData.invoiceTo = orderEntryObj?.invoiceToAccounts;
    updatedCartData.payerToAccount = orderEntryObj?.payerToAccounts;
    updatedCartData.isMultiShipTo =
      orderEntryObj?.shipToAccounts === "multiship";
    updatedCartData.isMultiRequestedDeliveryDate = isMultiRequestedDeliveryDate;
    updatedCartData.lineItems = prods?.shoppingCart?.lineItems;
    updatedCartData.isOrderEntryPage = true;
    if (orderEntryObj?.shipToAccounts === "multiship") {
      let selectedMultiShipAccs: any = [];
      shipToAccountsArray?.forEach(
        (acc: any) =>
          acc?.multiSelect && selectedMultiShipAccs?.push(acc?.value)
      );
      updatedCartData.shipToAccountIds = selectedMultiShipAccs;
      updatedCartData.shipToAccountId = "";
    } else if (isMultiRequestedDeliveryDate) {
      updatedCartData?.lineItems?.map((lineItem: any) => {
        let tempSublineItem: any = [];
        lineItem?.subLineItems?.map((subItem: any) => {
          tempSublineItem?.push({
            ...subItem,
            requestedDeliveryDate:
              subItem?.requestedDeliveryDate !== null
                ? moment(subItem?.requestedDeliveryDate).format("DD/MM/YYYY")
                : null,
          });
        });
        lineItem.subLineItems = tempSublineItem;
      });
    }
    //update API call
    if (updatedCartData?.lineItems?.length > 0) {
      dispatch(setLoading(true));
      dispatch(updateCartAction(updatedCartData));
    }
  };

  const clearOrder = () => {
    dispatch(setLoading(true));
    dispatch(
      clearCartOrder({
        shoppingContextToken,
        usernameOrEmail: userDetails?.username,
      })
    );
    dispatch(
      saveMultiAddresses({
        multiSelectArray: [],
      })
    );
  };

  const clearOrderRes = useSelector(
    (state: any) => state?.cartReducer?.clearOrderRes
  );

  useEffect(() => {
    dispatch(
      getCartProducts({
        shoppingContextToken,
        usernameOrEmail: userDetails?.username,
        key: 2,
      })
    );
  }, [clearOrderRes]);

  const [multiSelectChanged, setMultiSelectChanged] = useState(false);

  const handleAccountMultiSelection = (array: any) => {
    let multiSelectArray = array?.filter(
      (elem: any) => elem?.multiSelect === true
    );
    if (multiSelectArray?.length > 1) {
      dispatch(saveMultiAddresses({ multiSelectArray: array }));
      setShipToAccountsArray(array);
      closeModal();
      setMultiSelectChanged(true);
    } else {
      let msg = t("dnet.delivery_destinations_error.text");
      setModalErrMsg(msg);
    }
  };
  const handleFileChange = () => {
    setOpenFileModal(false);
  };
  const [isMultiRequestedDeliveryDate, setIsMultiRequestedDeliveryDate] =
    useState(false);

  return (
    <div>
      <Typography
        variant="subtitle1"
        className={styles.header}
        pb={3}
        color="primary"
      >
        {t("dnet.orderentry.section")}
      </Typography>
      <div>
        <Grid container spacing={2} className={styles.gridCustomDesign}>
          {topPageError !== "" && (
            <Grid item xs={12}>
              <div className={styles.errorBackground}>
                <Typography variant="body2">{topPageError}</Typography>
              </div>
            </Grid>
          )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.invoice_to.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography variant="body2">
              {currentDealerAccountFormat(saveAccountDetails?.invoiceToAcc)}
            </Typography>
          </Grid>
          {selectedCartProducts?.shippingAgentsAccountIds?.length >= 1 && (
            <>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2">
                  {t("dnet.shipping_agent.text")}
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12}>
                {selectedCartProducts?.shippingAgentsAccountIds?.length > 1 ? (
                  <CustomDropDown
                    id="shippingAgentAccountsID"
                    value={orderEntryObj?.shippingAgentAccounts}
                    menuOptions={shippingAgentsAccountsArray}
                    module="debitNoteCreation"
                    label=""
                    minWidth="90%"
                    handleChange={(e: any) => {
                      handleReportChange(
                        "shippingAgentAccounts",
                        e.target.value
                      );
                    }}
                  />
                ) : (
                  selectedCartProducts?.shippingAgentsAccountIds?.length ===
                    1 && (
                    <Typography variant="body2">
                      {createAddressFormat(
                        selectedCartProducts?.shippingAgentsAccountIds[0]
                      )}
                    </Typography>
                  )
                )}
              </Grid>
            </>
          )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.deliver_to.text")}</Typography>
          </Grid>
          {shipToAccountsArray?.length > 1 ? (
            <Grid
              item
              sm={10}
              xs={12}
              className={styles.gridMultiSelectCustomDesign}
            >
              <CustomDropDown
                id="shipToAccountId"
                value={orderEntryObj?.shipToAccounts}
                menuOptions={shipToAccountsArray}
                module="debitNoteCreation"
                label=""
                minWidth="90%"
                error={accHelperText !== ""}
                helperText={accHelperText}
                handleChange={(e: any) => {
                  handleReportChange("shipToAccounts", e.target.value);
                }}
              />
              {orderEntryObj?.shipToAccounts === "multiship" && (
                <CustomButton
                  id="select"
                  label={t("dnet.select.text")}
                  onClick={() => {
                    setOpenModal(!openModal);
                  }}
                  color="info.main"
                  margin="0 0 4px 0"
                />
              )}
            </Grid>
          ) : (
            <Grid
              item
              sm={10}
              xs={12}
              className={styles.gridMultiSelectCustomDesign}
            >
              <Typography variant="body2">
                {shipToAccountsArray[0]?.["label"]}
              </Typography>
            </Grid>
          )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">{t("dnet.payer.text")}</Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <Typography variant="body2">
              {currentDealerAccountFormat(saveAccountDetails?.payerToAcc)}
            </Typography>
            {/* } */}
          </Grid>
          {selectedCartProducts?.paymentTermEnabled &&
            selectedCartProducts?.paymentTermId?.length >= 1 && (
              <>
                <Grid item sm={2} xs={12}>
                  <Typography variant="body2">
                    {t("dnet.payment_term.text")}
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12}>
                  {selectedCartProducts?.paymentTermId?.length > 1 ? (
                    <CustomDropDown
                      id="paymentTermIds"
                      value={orderEntryObj?.paymentTerm}
                      menuOptions={paymentTermArray}
                      module="debitNoteCreation"
                      label=""
                      minWidth="90%"
                      // width="90%"
                      // error={accHelperText !== "" ? true : false}
                      // helperText={accHelperText}
                      handleChange={(e: any) => {
                        handleReportChange("paymentTerm", e.target.value);
                      }}
                    />
                  ) : (
                    selectedCartProducts?.paymentTermId?.length === 1 && (
                      <Typography variant="body2">
                        {`${selectedCartProducts?.paymentTermId[0]?.name}-${selectedCartProducts?.paymentTermId[0]?.description}`}
                      </Typography>
                    )
                  )}
                </Grid>
              </>
            )}
          <Grid item sm={2} xs={12}>
            <Typography variant="body2">
              {t("dnet.requested_delivery_date.text")}
            </Typography>
          </Grid>
          <Grid item sm={10} xs={12}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={styles.dateSection}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    openTo="day"
                    views={["year", "month", "day"]}
                    value={orderEntryObj?.requestedDeliveryDate}
                    onChange={(newValue: any) => {
                      handleReportChange("requestedDeliveryDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }}
                        size="small"
                      />
                    )}
                    inputFormat="DD/MM/YYYY"
                    minDate={startingMinDate}
                    shouldDisableDate={shouldDisableDate}
                    onError={(e: any) => handleDateErrorFunc(e)}
                  />
                </LocalizationProvider>
                {orderEntryObj?.shipToAccounts !== "multiship" &&
                  selectedCartProducts?.isMultiRequestedDeliveryDateEnabled &&
                  (isMultiRequestedDeliveryDate ? (
                    <CustomButton
                      id="singleDelivery"
                      label={t("dnet.single_delivery_date.text")}
                      onClick={() => {
                        setIsMultiRequestedDeliveryDate(
                          !isMultiRequestedDeliveryDate
                        );
                      }}
                      color="info.main"
                      margin="0 20px"
                    />
                  ) : (
                    <CustomButton
                      id="multiDelivery"
                      label={t("dnet.split.text")}
                      onClick={() => {
                        setIsMultiRequestedDeliveryDate(
                          !isMultiRequestedDeliveryDate
                        );
                      }}
                      color="info.main"
                      margin="0 20px"
                    />
                  ))}
              </div>
              <Typography variant="caption" ml={2} color="error">
                {dateError}
              </Typography>
            </div>
          </Grid>
          {selectedCartProducts?.isCompleteDeliveryEnabled && (
            <>
              <Grid item sm={2} xs={12}>
                <Typography variant="body2">
                  {t("dnet.delivery_question.text")}
                </Typography>
              </Grid>
              <Grid
                item
                sm={10}
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="partial"
                    name="radio-buttons-group"
                    row
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      checked={radioButtonValue === "partial"}
                      value="partial"
                      control={<Radio />}
                      label={t("dnet.partial.text")?.toString()}
                    />
                    <FormControlLabel
                      checked={radioButtonValue === "complete"}
                      value="complete"
                      control={<Radio />}
                      label={t("dnet.complete.text")?.toString()}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" className={styles.orderLine}>
              {orderLine}
            </Typography>
          </Grid>
          {orderEntryObj?.shipToAccounts === "multiship" && (
            <Grid item>
              <Stack direction="row" spacing={1}>
                <Chip
                  sx={currentActiveChip === 0 ? activeChip : chip}
                  onClick={() => handleChip("quantity")}
                  label={t("dnet.quantities.text")?.toString()}
                />
                <Chip
                  sx={currentActiveChip === 1 ? activeChip : chip}
                  onClick={() => handleChip("pricesAndAvailibility")}
                  label={t("dnet.prices_and_availability.text")?.toString()}
                />
              </Stack>
            </Grid>
          )}
          {((orderEntryObj?.shipToAccounts === "multiship" &&
            currentActiveChip !== 0) ||
            orderEntryObj?.shipToAccounts !== "multiship") && (
            <div
              style={{ width: "100%", paddingLeft: "16px", marginTop: "15px" }}
            >
              <SingleOrderEntryTable
                parent="orderEntry"
                orderEntryMultiship={
                  orderEntryObj?.shipToAccounts === "multiship"
                }
                handleOpenFileModal={handleOpenFileModal}
                isMultiRequestedDeliveryDate={
                  orderEntryObj?.shipToAccounts === "multiship"
                    ? false
                    : isMultiRequestedDeliveryDate
                }
                handleQuotationButton={handleQuotationButton}
                shouldDisableDate={shouldDisableDate}
                startingMinDate={startingMinDate}
                updateButtonClickHandler={updateButtonClickHandler}
                clearOrder={clearOrder}
                orderEntryObj={orderEntryObj}
              />
            </div>
          )}
          {orderEntryObj?.shipToAccounts === "multiship" &&
            currentActiveChip === 0 && (
              <Grid item xs={12}>
                <QuantitiesTable
                  handleOpenFileModal={handleOpenFileModal}
                  updateButtonClickHandler={updateButtonClickHandler}
                  clearOrder={clearOrder}
                  orderEntryObj={orderEntryObj}
                  currentChipName={currentChipName}
                  multiSelectChanged={multiSelectChanged}
                />
              </Grid>
            )}
        </Grid>
      </div>
      {openModal && (
        <OrderEntryModal
          closeModal={closeModal}
          openModal={openModal}
          shipToAccountsArray={shipToAccountsArray}
          handleAccountMultiSelection={handleAccountMultiSelection}
          modalErrMsg={modalErrMsg}
        />
      )}
      <FileUpload
        openFileModal={openFileModal}
        closeFileModal={closeFileModal}
        handleFileChange={handleFileChange}
        orderEntryObj={orderEntryObj}
        radioButtonValue={radioButtonValue}
        isMultiRequestedDeliveryDate={isMultiRequestedDeliveryDate}
      />
    </div>
  );
};
