import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../atoms/customButton";
import CustomTextField from "../../../atoms/customTextField";
import { plus2, minus2, fetchWarrantyProducts } from "../../../helper/util";
import styles from "./quantitiesTable.module.scss";
import { ROUTES_LABEL } from "../../../../constants/routesLabels";
import {
  deleteSelectedItemCart,
  fetchGlobalSearchSuggestions,
  fetchGlobalSearchSuggestionsSuccess,
  fetchProductDetailsData,
  getProductsByMaterialName,
  setCurrentProductSku,
} from "../../../../state/actions";
import moment from "moment";
const xmlImage = require("../../../../assets/images/discxml.gif");
const uploadImage = require("../../../../assets/images/discload.gif");
// var currencyFormatter = require("currency-formatter");
var js2xmlparser = require("js2xmlparser");
var _ = require("lodash");

export const QuantitiesTable = ({
  handleOpenFileModal,
  updateButtonClickHandler,
  clearOrder,
  orderEntryObj,
  currentChipName,
  multiSelectChanged,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentChipName === "pricesAndAvailibility")
      updateCart(currentChipName);
  }, [currentChipName]);

  const multiSelectedAddresses = useSelector(
    (state: any) => state?.cartReducer?.multiSelectedAddresses
  );

  useEffect(() => {
    let multiSelectArray = multiSelectedAddresses?.filter(
      (elem: any) => elem?.multiSelect === true
    );
    if (multiSelectArray?.length > 1 && multiSelectChanged) {
      updateCart();
    }
  }, [multiSelectedAddresses]);

  const dummyProductData = (Id: any) => {
    return {
      Id,
      checked: false,
      productSku: {
        name: "",
      },
      availabilityMap: {
        NOFG: {
          sku: {
            materialName: "",
            salesArea: "",
          },
          date: "",
          forecastGroup: "",
          quantity: "",
          status: "",
          countryOfOrigin: "",
          centralStockStatus: "",
          centralStockQuantity: "",
          centralStockDate: "",
        },
      },
      price: "",
      // {
      //     "currency": "",
      //     "retailPrice": ""
      // },
      description: "",
      uri: "",
      customerMaterialNumber: "",
      origin: "",
      specialPrice: "",
      isWarranty: false,
      warrantyLineItems: [],
      requestedDeliveryDate: "",
      quantity: "",
      subLineItems: [],
      quantityTotal: 0,
      multiLineItems: [],
    };
  };

  const tableHeaderData = [
    { id: "select", label: t("dnet.sel.text") },
    { id: "model", label: t("dnet.model.text"), minWidth: "250px" },
    { id: "address", label: "address", minWidth: "150px" },
    { id: "total", label: t("dnet.total_light.text") },
  ];

  const selectedCartProducts = useSelector(
    (state: any) => state?.cartReducer?.selectedCartProducts
  );

  const [prodContentData, setProdContentData] = useState<any>({});

  useEffect(() => {
    handleWarrantyLineItems();
  }, [prodContentData]);

  useEffect(() => {
    if (multiSelectedAddresses?.length > 0) {
      if (
        selectedCartProducts?.shipToAccountId?.length > 0 &&
        (selectedCartProducts?.shoppingCart?.lineItems?.length < 100 ||
          selectedCartProducts?.shoppingCart?.lineItems === undefined)
      ) {
        let prods: any = JSON.parse(JSON.stringify(selectedCartProducts));
        if (selectedCartProducts?.shoppingCart?.lineItems?.length > 0) {
          setUpdateButtonDisabled(false);
          let definedNumber =
            selectedCartProducts?.shoppingCart?.lineItems?.length > 7 &&
            selectedCartProducts?.shoppingCart?.lineItems?.length > 90
              ? 100 - selectedCartProducts?.shoppingCart?.lineItems?.length
              : selectedCartProducts?.shoppingCart?.lineItems?.length > 7
              ? 10
              : 3;
          for (let i = 0; i < definedNumber; i++) {
            prods?.shoppingCart?.lineItems?.push(dummyProductData(i + 1));
          }
          prods?.shoppingCart?.lineItems?.forEach((item: any) => {
            item.checked = false;
            item?.multiLineItems?.forEach((subItem: any) => {
              subItem.productSku = item?.productSku;
            });
          });
          setProdContentData(prods);
        } else {
          setUpdateButtonDisabled(true);
          let tempLineItems: any = [];
          for (let i = 0; i < 5; i++) {
            tempLineItems?.push(dummyProductData(i + 1));
          }
          prods.shoppingCart = {
            ...prods?.shoppingCart,
            lineItems: tempLineItems,
          };
          prods?.shoppingCart?.lineItems?.forEach((item: any) => {
            item.checked = false;
            item?.multiLineItems?.forEach((subItem: any) => {
              subItem.productSku = item?.productSku;
            });
          });
          setProdContentData(prods);
        }
      } else {
        let prods: any = JSON.parse(JSON.stringify(selectedCartProducts));
        prods?.shoppingCart?.lineItems?.forEach((item: any) => {
          item.checked = false;
          item?.multiLineItems?.forEach((subItem: any) => {
            subItem.productSku = item?.productSku;
          });
        });
        setUpdateButtonDisabled(false);
        setProdContentData(prods);
      }
    }
  }, [selectedCartProducts]);

  const updateProductQty = (
    qty: any = 0,
    accNum: any,
    index: any,
    type: any = "update",
    e: any = {}
  ) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let minQuantity: any = 0;
    let minDelQuantity: any = 0;
    let delUnit: any = 0;
    if (index?.toString()?.includes("_")) {
      let warrantIndex: any = index?.toString()?.split("_");
      minQuantity =
        prods?.shoppingCart?.lineItems[warrantIndex[1]]?.warrantyLineItems[
          warrantIndex[0]
        ]?.detail?.minOrderQuantity;
      minDelQuantity =
        prods?.shoppingCart?.lineItems[warrantIndex[1]]?.warrantyLineItems[
          warrantIndex[0]
        ]?.detail?.minDeliveryQuantity;
      delUnit =
        prods.shoppingCart.lineItems[warrantIndex[1]].warrantyLineItems[
          warrantIndex[0]
        ]?.detail?.deliveryUnit;

      minQuantity = parseInt(minQuantity) || 1;
      minDelQuantity = parseInt(minDelQuantity) || 1;
      delUnit = parseInt(delUnit) || 1;

      minQuantity = minQuantity === 0 ? 1 : minQuantity;
      minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
      delUnit = delUnit === 0 ? 1 : delUnit;

      let filterAcc: any = prods?.shoppingCart?.lineItems[
        parseInt(warrantIndex[1])
      ]?.warrantyLineItems[parseInt(warrantIndex[0])]?.multiLineItems?.filter(
        (prod: any) => prod?.ShiptoAccNo === accNum
      );
      if (filterAcc?.length > 0) {
        prods?.shoppingCart?.lineItems[
          parseInt(warrantIndex[1])
        ]?.warrantyLineItems[
          parseInt(warrantIndex[0])
        ]?.multiLineItems?.forEach((prod: any) => {
          if (prod?.ShiptoAccNo === accNum) {
            if (type === "increment" && parseInt(prod?.quantity) >= 0) {
              prod.quantity = plus2(
                parseInt(prod?.quantity),
                minQuantity,
                delUnit,
                minDelQuantity
              );
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal +=
                plus2(0, minQuantity, delUnit, minDelQuantity);
            } else if (type === "increment" && prod?.quantity === "") {
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal +=
                plus2(0, minQuantity, delUnit, minDelQuantity);
              prod.quantity = plus2(0, minQuantity, delUnit, minDelQuantity);
            } else if (type === "decrement" && parseInt(prod?.quantity) > 0) {
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal -=
                parseInt(prod?.quantity) -
                minus2(
                  parseInt(prod?.quantity),
                  minQuantity,
                  delUnit,
                  minDelQuantity
                );
              prod.quantity = minus2(
                parseInt(prod?.quantity),
                minQuantity,
                delUnit,
                minDelQuantity
              );
            } else if (
              type === "decrement" &&
              (parseInt(prod?.quantity) === 0 || prod?.quantity === "")
            ) {
              // prods.shoppingCart.lineItems[index].quantityTotal = parseInt(
              //   prods?.shoppingCart?.lineItems[index]?.quantityTotal
              // ) - parseInt(prod?.quantity);
              prod.quantity = 0;
            } else if (type === "update" && !isNaN(parseInt(qty))) {
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal =
                parseInt(
                  prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
                    ?.warrantyLineItems[parseInt(warrantIndex[0])]
                    ?.quantityTotal
                ) - (prod.quantity === "" ? 0 : parseInt(prod.quantity));
              prod.quantity = parseInt(qty);
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal =
                parseInt(
                  prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
                    ?.warrantyLineItems[parseInt(warrantIndex[0])]
                    ?.quantityTotal
                ) + parseInt(prod.quantity);
            } else if (
              type === "update" &&
              e.nativeEvent.inputType === "deleteContentBackward" &&
              (parseInt(qty) === 0 || qty === "")
            ) {
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal =
                parseInt(
                  prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
                    ?.warrantyLineItems[parseInt(warrantIndex[0])]
                    ?.quantityTotal
                ) - parseInt(prod?.quantity);
              prod.quantity = "";
            } else {
              prods.shoppingCart.lineItems[
                parseInt(warrantIndex[1])
              ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal =
                parseInt(
                  prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
                    ?.warrantyLineItems[parseInt(warrantIndex[0])]
                    ?.quantityTotal
                ) - parseInt(prod?.quantity);
              prod.quantity = 0;
            }
          }
        });
      } else {
        let multiShipToAccAdd: any = {
          quantity: 0,
          ShiptoAccNo: accNum,
        };
        if (type === "increment") {
          prods.shoppingCart.lineItems[
            parseInt(warrantIndex[1])
          ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal =
            prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
              ?.warrantyLineItems[parseInt(warrantIndex[0])]?.quantityTotal ===
            ""
              ? plus2(0, minQuantity, delUnit, minDelQuantity)
              : parseInt(
                  prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
                    ?.warrantyLineItems[parseInt(warrantIndex[0])]
                    ?.quantityTotal
                ) + plus2(0, minQuantity, delUnit, minDelQuantity);
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: plus2(0, minQuantity, delUnit, minDelQuantity),
          };
        } else if (type === "decrement") {
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: 0,
          };
        } else if (type === "update" && !isNaN(parseInt(qty))) {
          prods.shoppingCart.lineItems[
            parseInt(warrantIndex[1])
          ].warrantyLineItems[parseInt(warrantIndex[0])].quantityTotal =
            prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
              ?.warrantyLineItems[parseInt(warrantIndex[0])]?.quantityTotal ===
            ""
              ? parseInt(qty)
              : parseInt(
                  prods?.shoppingCart?.lineItems[parseInt(warrantIndex[1])]
                    ?.warrantyLineItems[parseInt(warrantIndex[0])]
                    ?.quantityTotal
                ) + parseInt(qty);
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: qty,
          };
        } else if (
          type === "update" &&
          e.nativeEvent.inputType === "deleteContentBackward" &&
          (parseInt(qty) === 0 || qty === "")
        ) {
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: "",
          };
        } else {
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: 0,
          };
        }
        prods?.shoppingCart?.lineItems[
          parseInt(warrantIndex[1])
        ]?.warrantyLineItems[parseInt(warrantIndex[0])]?.multiLineItems?.push(
          multiShipToAccAdd
        );
      }
    } else {
      minQuantity =
        prods.shoppingCart.lineItems[index]?.detail?.minOrderQuantity;
      minDelQuantity =
        prods.shoppingCart.lineItems[index]?.detail?.minDeliveryQuantity;
      delUnit = prods.shoppingCart.lineItems[index]?.detail?.deliveryUnit;

      minQuantity = parseInt(minQuantity) || 1;
      minDelQuantity = parseInt(minDelQuantity) || 1;
      delUnit = parseInt(delUnit) || 1;

      minQuantity = minQuantity === 0 ? 1 : minQuantity;
      minDelQuantity = minDelQuantity === 0 ? 1 : minDelQuantity;
      delUnit = delUnit === 0 ? 1 : delUnit;
      let filterAcc: any = prods?.shoppingCart?.lineItems[
        index
      ]?.multiLineItems?.filter((prod: any) => prod?.ShiptoAccNo === accNum);
      if (filterAcc?.length > 0) {
        prods?.shoppingCart?.lineItems[index]?.multiLineItems?.forEach(
          (prod: any) => {
            if (prod?.ShiptoAccNo === accNum) {
              if (type === "increment" && parseInt(prod?.quantity) >= 0) {
                prods.shoppingCart.lineItems[index].quantityTotal += plus2(
                  0,
                  minQuantity,
                  delUnit,
                  minDelQuantity
                );
                prod.quantity = plus2(
                  parseInt(prod?.quantity),
                  minQuantity,
                  delUnit,
                  minDelQuantity
                );
              } else if (type === "increment" && prod?.quantity === "") {
                prods.shoppingCart.lineItems[index].quantityTotal += plus2(
                  0,
                  minQuantity,
                  delUnit,
                  minDelQuantity
                );
                prod.quantity = plus2(0, minQuantity, delUnit, minDelQuantity);
              } else if (type === "decrement" && parseInt(prod?.quantity) > 0) {
                prods.shoppingCart.lineItems[index].quantityTotal -=
                  parseInt(prod?.quantity) -
                  minus2(
                    parseInt(prod?.quantity),
                    minQuantity,
                    delUnit,
                    minDelQuantity
                  );
                prod.quantity = minus2(
                  parseInt(prod?.quantity),
                  minQuantity,
                  delUnit,
                  minDelQuantity
                );
              } else if (
                type === "decrement" &&
                (parseInt(prod?.quantity) === 0 || prod?.quantity === "")
              ) {
                // prods.shoppingCart.lineItems[index].quantityTotal = parseInt(
                //   prods?.shoppingCart?.lineItems[index]?.quantityTotal
                // ) - parseInt(prod?.quantity);
                prod.quantity = 0;
              } else if (type === "update" && !isNaN(parseInt(qty))) {
                prods.shoppingCart.lineItems[index].quantityTotal =
                  parseInt(
                    prods?.shoppingCart?.lineItems[index]?.quantityTotal
                  ) - (prod.quantity === "" ? 0 : parseInt(prod.quantity));
                prod.quantity = parseInt(qty);
                prods.shoppingCart.lineItems[index].quantityTotal =
                  parseInt(
                    prods?.shoppingCart?.lineItems[index]?.quantityTotal
                  ) + parseInt(prod.quantity);
              } else if (
                type === "update" &&
                e.nativeEvent.inputType === "deleteContentBackward" &&
                (parseInt(qty) === 0 || qty === "")
              ) {
                prods.shoppingCart.lineItems[index].quantityTotal =
                  parseInt(
                    prods?.shoppingCart?.lineItems[index]?.quantityTotal
                  ) - parseInt(prod?.quantity);
                prod.quantity = "";
              } else {
                prods.shoppingCart.lineItems[index].quantityTotal =
                  parseInt(
                    prods?.shoppingCart?.lineItems[index]?.quantityTotal
                  ) - parseInt(prod?.quantity);
                prod.quantity = 0;
              }
            }
          }
        );
      } else {
        let multiShipToAccAdd: any = {
          quantity: 0,
          ShiptoAccNo: accNum,
        };
        if (type === "increment") {
          prods.shoppingCart.lineItems[index].quantityTotal =
            prods?.shoppingCart?.lineItems[index]?.quantityTotal === ""
              ? plus2(0, minQuantity, delUnit, minDelQuantity)
              : parseInt(prods?.shoppingCart?.lineItems[index]?.quantityTotal) +
                plus2(0, minQuantity, delUnit, minDelQuantity);
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: plus2(0, minQuantity, delUnit, minDelQuantity),
          };
        } else if (type === "decrement") {
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: 0,
          };
        } else if (type === "update" && !isNaN(parseInt(qty))) {
          prods.shoppingCart.lineItems[index].quantityTotal =
            prods?.shoppingCart?.lineItems[index]?.quantityTotal === ""
              ? parseInt(qty)
              : parseInt(prods?.shoppingCart?.lineItems[index]?.quantityTotal) +
                parseInt(qty);
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: qty,
          };
        } else if (
          type === "update" &&
          e.nativeEvent.inputType === "deleteContentBackward" &&
          (parseInt(qty) === 0 || qty === "")
        ) {
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: "",
          };
        } else {
          multiShipToAccAdd = {
            ...multiShipToAccAdd,
            quantity: 0,
          };
        }
        prods?.shoppingCart?.lineItems[index]?.multiLineItems?.push(
          multiShipToAccAdd
        );
      }
    }
    setProdContentData(prods);
  };

  let uploadImageAltTag = t("dnet.load_file.text").split('"');
  let uploadImageAlt = uploadImageAltTag[uploadImageAltTag?.length - 2];
  let xmlImageAltTag = t("dnet.save_as_XML").split('"');
  let xmlImageAlt = xmlImageAltTag[xmlImageAltTag?.length - 2];

  const handleMultishipAccountQuantityMapping = (
    accNum: any,
    index: any,
    isWarrantyProd: any
  ) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let mappedAccount: any = [];
    if (isWarrantyProd) {
      let warrantyIndex = index?.toString()?.split("_");
      mappedAccount = prods?.shoppingCart?.lineItems?.[
        parseInt(warrantyIndex[1])
      ]?.warrantyLineItems[parseInt(warrantyIndex[0])]?.multiLineItems?.filter(
        (acc: any) => acc?.ShiptoAccNo === accNum
      );
    } else {
      mappedAccount = prods?.shoppingCart?.lineItems?.[
        index
      ]?.multiLineItems?.filter((acc: any) => acc?.ShiptoAccNo === accNum);
    }
    return mappedAccount?.length === 0 ? "" : mappedAccount?.[0]?.quantity;
  };

  const handleProductNameChange = (name: any, index: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    prods.shoppingCart.lineItems[index].productSku = {
      ...prods?.shoppingCart?.lineItems[index]?.productSku,
      name,
    };
    setProdContentData(prods);
  };

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);

  const handleOnBlurMaterialNameChange = (index: any) => {
    if (
      prodContentData?.shoppingCart?.lineItems[index]?.productSku?.name !== ""
    ) {
      setUpdateButtonDisabled(false);
    } else {
      let checkAnyProductName: any = [];
      checkAnyProductName = prodContentData?.shoppingCart?.lineItems?.filter(
        (elem: any) => {
          return elem?.productSku?.name !== "";
        }
      );
      if (checkAnyProductName?.length > 0) {
        setUpdateButtonDisabled(false);
      } else {
        setUpdateButtonDisabled(true);
      }
    }
  };

  const [productSearchButtonClicked, setProductSearchButtonClicked] =
    useState(false);

  const selectedProductSearch = (product: any) => {
    setProductSearchButtonClicked(true);
    dispatch(
      fetchGlobalSearchSuggestions({
        searchString: product?.productSku?.name,
      })
    );
  };

  const globalSearchSuggestions = useSelector(
    (state: any) => state?.commonReducer?.globalSearchSuggestions
  );

  useEffect(() => {
    if (!_.isEmpty(globalSearchSuggestions) && productSearchButtonClicked) {
      let productArr: any = globalSearchSuggestions.map(
        (product: any) => product?.item
      );
      dispatch(
        getProductsByMaterialName({
          materialName: productArr,
        })
      );
      dispatch(fetchGlobalSearchSuggestionsSuccess([]));
      navigate(`/${ROUTES_LABEL.GLOBAL_SEARCH}`);
    }
  }, [globalSearchSuggestions]);

  const [selectAllItems, setSelectAllItems] = useState(false);

  const checkHandler = (value: any, index: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    prods.shoppingCart.lineItems[index].checked = value;
    setProdContentData(prods);
  };
  const masterCheckHandler = (value: any) => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    for (let i = 0; i < prods.shoppingCart.lineItems?.length; i++) {
      prods.shoppingCart.lineItems[i].checked = value;
    }
    setProdContentData(prods);
  };

  const [selectedPrimeSupport, setSelectedPrimeSupport] = useState<any>([]);
  const toggleWarrantyLines = (index: any) => {
    if (selectedPrimeSupport.indexOf(index) == -1) {
      setSelectedPrimeSupport([...selectedPrimeSupport, index]);
    } else {
      setSelectedPrimeSupport(
        selectedPrimeSupport.filter((idx: any) => idx !== index)
      );
    }
  };

  const isProductDetailsVisible = (indexObj: any) => {
    if (indexObj?.index?.uri) {
      return true;
    } else {
      return false;
    }
  };

  const productByMaterialName = useSelector(
    (state: any) => state?.productReducer?.productsByMaterialName
  );

  const [productDetailsPageClick, setProductDetailsPageClick] = useState(false);

  const redirectToDetails = (product: any) => {
    dispatch(
      fetchProductDetailsData({ productNames: product?.index?.materialName })
    );
    setProductDetailsPageClick(true);
    dispatch(
      getProductsByMaterialName({ materialName: [product?.productSku?.name] })
    );
  };

  useEffect(() => {
    if (productByMaterialName?.length > 0 && productDetailsPageClick) {
      let productObj = Object.values(productByMaterialName[0]);
      dispatch(setCurrentProductSku(fetchWarrantyProducts(productObj)[0]));
      navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}`);
    }
  }, [productByMaterialName]);

  const handleDownloadXml = () => {
    let requiredJsonFormat: any = {
      Orders: {
        Order: {
          Header: {
            PONumber: " ",
            RequestedDeliveryDate: moment(
              orderEntryObj?.requestedDeliveryDate
            ).format("DD/MM/YYYY"),
          },
          Partners: {
            Partner: [],
          },
          Items: {
            Item: [],
          },
        },
      },
    };
    let itemsArr: any = [];
    prodContentData?.shoppingCart?.lineItems?.forEach((lineItem: any) => {
      lineItem?.productSku?.name !== "" &&
        itemsArr?.push({
          Material: lineItem?.productSku?.name,
          OrderQuantity: lineItem?.quantityTotal,
          ShipTos: {
            ShipTo: [],
          },
        });
      lineItem?.productSku?.name !== "" &&
        lineItem?.multiLineItems?.forEach((subMultiShip: any) => {
          let shipTo: any = [...itemsArr[itemsArr.length - 1]?.ShipTos?.ShipTo];
          subMultiShip?.quantity !== "" &&
            shipTo.push({
              "@": {
                ID: subMultiShip?.ShiptoAccNo,
                Qty: subMultiShip?.quantity,
              },
            });
          itemsArr[itemsArr.length - 1] = {
            ...itemsArr[itemsArr.length - 1],
            ShipTos: {
              ShipTo: shipTo,
            },
          };
        });
      lineItem?.warrantyLineItems?.length > 0 &&
        lineItem?.isWarranty &&
        lineItem?.warrantyLineItems?.forEach((subLineItem: any) => {
          itemsArr?.push({
            Material: subLineItem?.productSku?.name,
            OrderQuantity: subLineItem?.quantityTotal,
            ShipTos: {
              ShipTo: [],
            },
          });
          subLineItem?.multiLineItems?.forEach((subMultiShip: any) => {
            let shipTo: any = [
              ...itemsArr[itemsArr.length - 1]?.ShipTos?.ShipTo,
            ];
            subMultiShip?.quantity !== "" &&
              shipTo.push({
                "@": {
                  ID: subMultiShip?.ShiptoAccNo,
                  Qty: subMultiShip?.quantity,
                },
              });
            itemsArr[itemsArr.length - 1] = {
              ...itemsArr[itemsArr.length - 1],
              ShipTos: {
                ShipTo: shipTo,
              },
            };
          });
        });
    });
    itemsArr?.length === 0 &&
      itemsArr?.push({
        Material: " ",
        OrderQuantity: " ",
        ShipTos: {
          ShipTo: [
            {
              "@": {
                ID: "",
                Qty: "",
              },
            },
            {
              "@": {
                ID: "",
                Qty: "",
              },
            },
          ],
        },
      });
    requiredJsonFormat.Orders.Order.Items.Item = itemsArr;
    requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({
      "@": {
        ID: currentDealer?.accNum,
        Type: "Payer",
      },
    });
    requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({
      "@": {
        ID: currentDealer?.accNum,
        Type: "InvoiceTo",
      },
    });
    requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({
      "@": {
        ID: currentDealer?.accNum,
        Type: "SoldTo",
      },
    });
    prodContentData?.shipToAccountId?.forEach((shipToAcc: any) => {
      requiredJsonFormat?.Orders?.Order?.Partners?.Partner?.push({
        "@": {
          ID: shipToAcc?.accountNumber,
          Type: "ShipTo",
        },
      });
    });
    const xmlData = js2xmlparser.parse("Xml4Sap", requiredJsonFormat);
    const blob = new Blob([xmlData], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "SONYORDER.xml";
    a.click();
    URL.revokeObjectURL(url);
  };

  const currentDealer = useSelector(
    (state: any) => state?.commonReducer?.currentDealer
  );
  const shoppingContextToken = `${currentDealer?.accNum}_${currentDealer?.bgName}_${currentDealer?.loggedInUser?.username}_${currentDealer?.loggedInUser?.profile?.email}`;

  const userDetails = useSelector(
    (state: any) => state?.commonReducer?.loginResponse?.data?.userDetails
  );

  const deleteHandler = () => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    let newLineItems: any = [];
    prods?.shoppingCart?.lineItems?.forEach(
      (prod: any) =>
        prod?.checked === true && newLineItems?.push(prod?.productSku?.name)
    );
    if (newLineItems?.length > 0) {
      dispatch(
        deleteSelectedItemCart({
          shoppingContextToken,
          product: newLineItems,
          usernameOrEmail: userDetails?.username,
        })
      );
    }
  };

  const globalPayloadStructure = () => {
    let prods = JSON.parse(JSON.stringify(prodContentData));
    if (prods?.shoppingCart?.lineItems) {
      let lineItem: any = [];
      let multiSelectArray = multiSelectedAddresses?.filter(
        (elem: any) => elem?.multiSelect === true
      );
      if (multiSelectArray?.length > 1) {
        let actualProds = prodContentData?.shoppingCart?.lineItems?.filter(
          (elem: any) => elem?.productSku?.name !== ""
        );
        if (actualProds?.length > 0) {
          prodContentData?.shoppingCart?.lineItems?.forEach(
            (item: any, index: any) => {
              let tempMultiLineItem: any = [];
              if (item?.productSku?.name !== "") {
                let sum = 0;
                item?.multiLineItems?.forEach((multiItem: any) => {
                  let addressDetails = multiSelectArray?.filter(
                    (elem: any) =>
                      elem?.value === multiItem?.ShiptoAccNo &&
                      multiItem?.quantity !== ""
                  );
                  if (addressDetails?.length > 0) {
                    sum +=
                      multiItem?.quantity === ""
                        ? 0
                        : parseInt(multiItem?.quantity);
                    tempMultiLineItem?.push(multiItem);
                  }
                });
                lineItem?.push(item);
                lineItem[index].multiLineItems = tempMultiLineItem;
                lineItem[index].quantityTotal = sum;
                prods.shoppingCart.lineItems = lineItem;
              }
            }
          );
        } else {
          prods.shoppingCart.lineItems = [];
        }
      }
    }
    let selectedAddressesValue = multiSelectedAddresses
      .filter((addr: any) => addr.multiSelect)
      .map((addr: any) => addr.value);
    if (prods?.shoppingCart?.lineItems) {
      prods?.shoppingCart?.lineItems.forEach((lineItem: any) => {
        if (lineItem.isWarranty && lineItem?.warrantyLineItems?.length > 0) {
          lineItem?.warrantyLineItems?.forEach((warrantyLineItem: any) => {
            warrantyLineItem.multiLineItems =
              warrantyLineItem?.multiLineItems?.filter((multiLineItem: any) =>
                selectedAddressesValue.includes(multiLineItem?.ShiptoAccNo)
              );
            warrantyLineItem.quantityTotal =
              warrantyLineItem?.multiLineItems?.reduce(
                (total: any, multiLineItem: any) =>
                  total + parseInt(multiLineItem?.quantity),
                0
              );
            // warrantyLineItem.quantityTotal =
            //   warrantyLineItem?.quantityTotal === undefined ||
            //   isNaN(warrantyLineItem?.quantityTotal)
            //     ? 0
            //     : warrantyLineItem?.quantityTotal;
          });
        }
      });
    }

    return prods;
  };

  function handleWarrantyLineItems() {
    prodContentData?.shoppingCart?.lineItems?.map((item: any) => {
      if (item?.isWarranty === true) {
        item?.warrantyLineItems.map((warrProd: any) => {
          if (!warrProd.hasOwnProperty("multiLineItems")) {
            warrProd["multiLineItems"] = [];
          }
          if (!warrProd.hasOwnProperty("quantityTotal")) {
            warrProd["quantityTotal"] = 0;
          }
          if (!warrProd.hasOwnProperty("quantity")) {
            warrProd["quantity"] = 0;
          }
        });
      }
    });
  }

  const updateCart = (chip: any = "") => {
    handleWarrantyLineItems();
    let payloadProds = globalPayloadStructure();
    payloadProds = {
      ...payloadProds,
      chip,
    };
    updateButtonClickHandler(payloadProds);
  };

  const handleQuoteHandler = () => {
    handleWarrantyLineItems();
    let payloadProds = globalPayloadStructure();
    // handleQuotationButton(payloadProds)
    updateButtonClickHandler(payloadProds, true);
  };

  const tableBodyStructure = (product: any, index: any) => {
    return (
      <TableRow
        tabIndex={-1}
        key={index}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell>
          <Box className={styles.modelColumn}>
            {!index?.toString()?.includes("_") ? (
              <Checkbox
                sx={{ paddingLeft: 0 }}
                checked={product?.checked}
                onChange={(e: any) => {
                  checkHandler(e.target.checked, index);
                }}
              />
            ) : (
              <SubdirectoryArrowRightIcon />
            )}
          </Box>
        </TableCell>
        <TableCell
          style={{
            position: "sticky",
            left: "0",
            zIndex: "1",
            background: "#fff",
          }}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <CustomTextField
              label=""
              value={product?.productSku?.name}
              changeHandler={(e: any) =>
                handleProductNameChange(e.target.value, index)
              }
              onBlurHandler={() => handleOnBlurMaterialNameChange(index)}
              additionalSxProperties={{
                width: "150px",
                height: "15px",
                minWidth: "50px",
              }}
            />
            <IconButton
              title={t("dnet.search_order_for_product_alt.text")?.toString()}
              onClick={() => selectedProductSearch(product)}
            >
              <SearchIcon />
            </IconButton>
            <span>
              {isProductDetailsVisible(product) && (
                <CustomButton
                  label={<InfoOutlinedIcon />}
                  color="primary"
                  additionalSxProperties={{
                    width: "max-content",
                  }}
                  onClick={() => {
                    redirectToDetails(product);
                  }}
                />
              )}
            </span>
            <span>
              {product?.isWarranty &&
                product?.warrantyLineItems?.length > 0 && (
                  <CustomButton
                    label={<AddCircleOutlineOutlinedIcon />}
                    additionalSxProperties={{
                      width: "max-content",
                    }}
                    onClick={() => {
                      toggleWarrantyLines(index);
                    }}
                  />
                )}
            </span>
          </div>
        </TableCell>
        {multiSelectedAddresses?.length > 0 && //prodContentData
          multiSelectedAddresses?.map((acc: any, prodIndex: any) => {
            return (
              acc?.multiSelect &&
              acc?.value !== 0 &&
              acc?.value !== "multiselect" && (
                <TableCell key={acc?.value} id={acc?.value}>
                  <Box>
                    <Box className={styles.qtyButtonContaainer}>
                      <CustomTextField
                        additionalSxProperties={{
                          width: "50px",
                          height: "15px",
                        }}
                        label={""}
                        value={handleMultishipAccountQuantityMapping(
                          acc?.value,
                          index,
                          index?.toString()?.includes("_")
                        )}
                        changeHandler={(e: any) => {
                          e.target.value = e.target.value
                            .toString()
                            .slice(0, 6);
                          updateProductQty(
                            e.target.value,
                            acc?.value,
                            index,
                            "update",
                            e
                          );
                        }}
                      />
                      <Box>
                        <IconButton
                          onClick={() =>
                            updateProductQty(0, acc?.value, index, "decrement")
                          }
                          title={t("dnet.decrement_info.text")?.toString()}
                        >
                          <RemoveCircleOutlineOutlinedIcon />
                        </IconButton>
                        <IconButton
                          key={acc?.value}
                          id={acc?.value}
                          onClick={() =>
                            updateProductQty(0, acc?.value, index, "increment")
                          }
                          title={t("dnet.increment_info.text")?.toString()}
                        >
                          <AddCircleOutlineOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box className={styles.qtyText}>
                      <span className={styles.messageStyle}>
                        {product?.quantityMessageTarget}
                      </span>
                    </Box>
                  </Box>
                </TableCell>
              )
            );
          })}
        <TableCell>{product?.quantityTotal}</TableCell>
      </TableRow>
    );
  };

  return (
    <Box className={styles.tableBox}>
      <div className={styles.tableHeader}>
        <CustomButton
          id="update"
          label={t("dnet.update.text")}
          onClick={() => updateCart()}
          disabled={updateButtonDisabled}
          color="info.main"
          margin="0"
        />
        <CustomButton
          id="getQuote"
          label={t("dnet.get_quote.text")}
          onClick={() => handleQuoteHandler()}
          disabled={updateButtonDisabled}
          color="warning.main"
          margin="0"
        />
      </div>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaderData.map((headCell: any) => (
                <React.Fragment key={headCell?.id}>
                  {headCell?.id === "address" ? (
                    multiSelectedAddresses?.map((item: any) => {
                      return (
                        item?.multiSelect &&
                        item?.value !== 0 &&
                        item?.value !== "multiselect" && (
                          <TableCell
                            key={item?.value}
                            sx={{ minWidth: headCell?.minWidth }}
                          >
                            {item?.label}
                          </TableCell>
                        )
                      );
                    })
                  ) : headCell?.id === "select" ? (
                    <TableCell align="center">
                      <Checkbox
                        checked={selectAllItems}
                        sx={{ paddingLeft: 0 }}
                        onChange={(e: any) => {
                          setSelectAllItems(!selectAllItems);
                          masterCheckHandler(e.target.checked);
                        }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{ minWidth: headCell?.minWidth }}
                      style={
                        headCell?.id === "model"
                          ? { position: "sticky", left: "0", zIndex: "1" }
                          : {}
                      }
                    >
                      {headCell.label}
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {prodContentData?.shoppingCart?.lineItems?.length > 0 ? (
              <>
                {prodContentData?.shoppingCart?.lineItems?.map(
                  (product: any, index: any) => {
                    return (
                      <>
                        {tableBodyStructure(product, index)}
                        {product?.isWarranty &&
                          product?.warrantyLineItems?.length > 0 &&
                          selectedPrimeSupport?.indexOf(index) !== -1 &&
                          product?.warrantyLineItems?.map(
                            (warrantyProduct: any, key: any) => {
                              return (
                                <>
                                  {tableBodyStructure(
                                    warrantyProduct,
                                    `${key}_${index}`
                                  )}
                                </>
                              );
                            }
                          )}
                      </>
                    );
                  }
                )}
              </>
            ) : (
              <TableRow>
                <TableCell
                  colSpan={8}
                  align="center"
                  className={styles.noRecFound}
                >
                  {t("gzl.no_data_available.text")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.tableFooter}>
        <div className={styles.totalAmount}>
          <div className={styles.selectedItemSection}>
            <Typography variant="caption" mt={0.2}>
              {t("dnet.selected_items.text")}
            </Typography>
            <CustomButton
              id="deleteItem"
              label={t("dnet.delete.text")}
              onClick={() => deleteHandler()}
              color="warning.main"
            />
          </div>
          <CustomButton
            id="clearOrder"
            label={t("dnet.clear_order.text")}
            onClick={clearOrder}
            color="info.main"
          />
          <CustomButton
            id="xml"
            label={<img src={xmlImage} alt={xmlImageAlt} />}
            onClick={handleDownloadXml}
            color="info.main"
          />
          <CustomButton
            id="upload"
            label={<img src={uploadImage} alt={uploadImageAlt} />}
            onClick={handleOpenFileModal}
            color="info.main"
          />
        </div>
      </div>
      <div></div>
    </Box>
  );
};

QuantitiesTable.defaultProps = {
  shipToAccountsArray: [],
  handleOpenFileModal: () => {},
  handleQuotationButton: () => {},
};
